import { useContext } from '@nuxtjs/composition-api'
import type { EditorProps } from '@tiptap/pm/view'

export const handlePaste = (context: ReturnType<typeof useContext>): EditorProps['handlePaste'] => {
  return (view, event) => {
    const transferItem = event.clipboardData?.items[0]
    const file = transferItem?.getAsFile()

    if (file) {
      // Загрузка изображения через сервис временных файлов и вставка изображения в контент
      if (allowedEditorImageTypes.includes(file.type)) {
        context.$services.file.uploadFile(file).then((src) => {
          if (src) {
            const { schema } = view.state

            const node = schema.nodes.imageResize.create({ src })
            const transaction = view.state.tr.replaceSelectionWith(node)

            view.dispatch(transaction)
          }
        })

        return true
      }
    }

    return false
  }
}

export const allowedEditorImageTypes = ['image/png', 'image/jpeg']
