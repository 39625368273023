
import { defineComponent } from 'vue'
import { computed } from '@nuxtjs/composition-api'
import { SvoiTooltipped } from '@svoi-ui/components/tooltip'
import { getEditorToolsByKeys, useEditor } from '~/composables/editor'
import { EditorActivatorDropdown, EditorDropdownItem } from '~/widgets/editor/editor-tools/dropdown'
import { BaseDropdown } from '~/shared/ui/base'
import type { EditorTools } from '~/interfaces/editor'
import type { HeadingLevel } from '~/shared/types'

export default defineComponent({
  components: { SvoiTooltipped, EditorActivatorDropdown, EditorDropdownItem, BaseDropdown },
  setup() {
    const editor = useEditor()
    const t = getEditorToolsByKeys(editor, ['paragraph', 'heading'])

    const getHeadingLevel = (tool: EditorTools['heading'], level: HeadingLevel) => {
      return {
        key: tool.key,
        active: tool.active(level),
        action: () => tool.action(level),
        disabled: tool.disabled(level),
      }
    }

    const tools = computed(() => {
      if (!t.value) {
        return null
      }

      return [
        {
          ...t.value.paragraph,
          className: 'text',
          title: 'Обычный текст',
        },
        {
          ...getHeadingLevel(t.value.heading, 2),
          className: 'h2',
          title: 'Заголовок 1',
        },
        {
          ...getHeadingLevel(t.value.heading, 3),
          className: 'h3',
          title: 'Заголовок 2',
        },
        {
          ...getHeadingLevel(t.value.heading, 4),
          className: 'h4',
          title: 'Заголовок 3',
        },
      ]
    })

    const activeItem = computed(() => {
      return tools.value?.find((item) => item.active) || { title: 'Обычный текст' }
    })

    return {
      tools,
      activeItem,
    }
  },
})
