
import { defineComponent } from 'vue'
import { computed, PropType } from '@nuxtjs/composition-api'
import { SvoiIcon } from '@svoi-ui/components/icon'
import { getEditorToolsByKeys } from '~/composables/editor/use-editor-tools'
import { useEditor } from '~/composables/editor'
import { EditorGeneralKeyTools } from '~/interfaces/editor/editor-tools'

export default defineComponent({
  components: { SvoiIcon },
  props: {
    toolKey: {
      type: String as PropType<EditorGeneralKeyTools>,
      default: '',
    },
    icon: {
      type: String,
      required: true,
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    const editor = useEditor()

    const t = getEditorToolsByKeys(editor, [props.toolKey])

    const tool = computed(() => t.value?.[props.toolKey])

    const classes = computed(() => ({
      'tool-active': tool.value?.active,
      'tool-disabled': tool.value?.disabled,
      tool: true,
    }))

    const onClickTool = () => {
      tool.value?.action()

      emit('click')
    }

    return {
      tool,
      classes,
      onClickTool,
    }
  },
})
