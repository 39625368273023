
import { defineComponent } from 'vue'
import { computed } from '@nuxtjs/composition-api'
import { SvoiIcon } from '@svoi-ui/components/icon'

export default defineComponent({
  components: { SvoiIcon },

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    hideArrow: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const classes = computed(() => {
      return {
        wrapper: {
          'activator-dropdown': true,
          'activator-dropdown-active': props.isOpen,
        },
        icon: {
          'icon-arrow': true,
          'icon-arrow-active': props.isOpen,
        },
      }
    })

    return {
      classes,
    }
  },
})
