import { OrderedList } from '@tiptap/extension-ordered-list'
import { BulletList } from '@tiptap/extension-bullet-list'
import { TextAlign } from '@tiptap/extension-text-align'
import { Underline } from '@tiptap/extension-underline'
import { Subscript } from '@tiptap/extension-subscript'
import { Superscript } from '@tiptap/extension-superscript'
import { Link } from '@tiptap/extension-link'
import { Table } from '@tiptap/extension-table'
import { TableRow } from '@tiptap/extension-table-row'
import { TableHeader } from '@tiptap/extension-table-header'
import { TextStyle } from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'
import { StarterKit } from '@tiptap/starter-kit'
import { Youtube } from '@tiptap/extension-youtube'
import { BubbleMenu } from '@tiptap/extension-bubble-menu'
import { Typography } from '@tiptap/extension-typography'
import { Heading } from './heading'
import { ImageResize } from './image-resize'
import { Rutube } from './rutube-video'
import { TableCell } from './table-cell'
import { Vk } from './vk-video'
import type { EditorExtensionsProps } from '~/interfaces/editor'

export const getEditorExtensions = (props: EditorExtensionsProps) => [
  StarterKit,
  TextStyle,
  OrderedList.configure({
    itemTypeName: 'listItem',
    HTMLAttributes: {
      class: 'editor__ordered-list',
    },
  }),
  Heading,
  BulletList.configure({
    itemTypeName: 'listItem',
    HTMLAttributes: {
      class: 'editor__bullet-list',
    },
  }),
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  Underline,
  Subscript,
  Superscript,
  Color.configure({
    types: ['textStyle'],
  }),
  Link.extend({ inclusive: false }).configure({
    linkOnPaste: true,
    autolink: true,
    openOnClick: props.link.openOnClick,
    HTMLAttributes: {
      target: '_blank',
    },
  }),
  Table.configure({
    resizable: true,
    allowTableNodeSelection: true,
    cellMinWidth: 100,
  }),
  TableRow,
  TableHeader,
  TableCell,
  ImageResize.configure({
    inline: true,
  }),
  BubbleMenu.configure({
    pluginKey: 'editor_table-menu',
  }),
  Youtube.configure({
    ccLanguage: 'ru',
    inline: true,
    height: 300,
  }),
  Rutube.configure({
    inline: true,
    height: 300,
  }),
  Vk.configure({
    inline: true,
    height: 360,
  }),
  Typography.configure({
    openDoubleQuote: '”',
    openSingleQuote: '’',
    closeDoubleQuote: '“',
    closeSingleQuote: '‘',
  }),
]
