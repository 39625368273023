
import { computed, defineComponent, PropType, reactive } from '@nuxtjs/composition-api'
import { SvoiIcon } from '@svoi-ui/components/icon'
import { SvoiInput } from '@svoi-ui/components/input'
import { useVuelidate } from '@vuelidate/core'
import { SvoiButton } from '@svoi-ui/components/button'
import { getEditorToolsByKeys, useEditor } from '~/composables/editor'
import { requiredRule, websiteRule } from '~/shared/utils/validation'
import { BaseDropdown } from '~/shared/ui/base'
import { EditorActivatorDropdown } from '~/widgets/editor/editor-tools/dropdown'

export default defineComponent({
  components: { SvoiButton, SvoiInput, BaseDropdown, SvoiIcon, EditorActivatorDropdown },
  props: {
    position: {
      type: Object as PropType<typeof BaseDropdown.props.position>,
      default: null,
    },
  },

  setup() {
    const editor = useEditor()
    const tool = getEditorToolsByKeys(editor, ['addLink'])

    const form = useVuelidate({ link: { ...requiredRule, ...websiteRule } }, reactive({ link: '' }), {
      $stopPropagation: true,
    })

    const isActiveLink = computed(() => !!tool.value?.addLink.active)

    const updateButtonSettings = computed(() => {
      return {
        theme: 'qiwi',
        size: 'm',
        disabled: form.value.$invalid,
        name: isActiveLink.value ? 'Обновить' : 'Сохранить',
      }
    })

    const onClickUpdate = () => {
      form.value.$validate().then((isValid) => {
        if (isValid) {
          tool.value?.addLink.action(form.value.link.$model || '')
        }
      })
    }

    const onOpenDropdown = (isOpen: boolean) => {
      if (isOpen) {
        form.value.link.$model = tool.value?.addLink.currentLink() || ''
        form.value.$reset()
      }
    }

    return {
      tool,
      form,
      isActiveLink,
      updateButtonSettings,
      onClickUpdate,
      onOpenDropdown,
    }
  },
})
