
import { defineComponent } from 'vue'
import { computed } from '@nuxtjs/composition-api'
import { SvoiIcon } from '@svoi-ui/components/icon'
import { SvoiTooltipped } from '@svoi-ui/components/tooltip'
import { getEditorToolsByKeys, useEditor } from '~/composables/editor'
import { EditorActivatorDropdown, EditorDropdownItem } from '~/widgets/editor/editor-tools/dropdown'
import { BaseDropdown } from '~/shared/ui/base'

export default defineComponent({
  components: {
    SvoiTooltipped,
    SvoiIcon,
    EditorActivatorDropdown,
    EditorDropdownItem,
    BaseDropdown,
  },
  setup() {
    const editor = useEditor()
    const t = getEditorToolsByKeys(editor, ['left', 'right', 'center', 'justify'])

    const tools = computed(() => {
      if (!t.value) {
        return null
      }

      return [
        {
          ...t.value.left,
          icon: 'paragraph/textalign-left',
          title: 'По левому краю',
        },
        {
          ...t.value.right,
          icon: 'paragraph/textalign-right',
          title: 'По правому краю',
        },
        {
          ...t.value.center,
          icon: 'paragraph/textalign-center',
          title: 'По центру',
        },
        {
          ...t.value.justify,
          icon: 'paragraph/textalign-justifycenter',
          title: 'По ширине',
        },
      ]
    })

    const activeItem = computed(() => {
      return (
        tools.value?.find((item) => item.active) || {
          icon: 'paragraph/textalign-left',
        }
      )
    })

    return {
      tools,
      activeItem,
    }
  },
})
