
import Vue from 'vue'
import { computed, onMounted, onUnmounted, reactive, Ref, ref } from '@nuxtjs/composition-api'
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-2'

export default Vue.extend({
  components: {
    NodeViewWrapper,
  },
  props: nodeViewProps,

  setup(props) {
    const isActive = ref(false)
    const isResizing = ref(false)
    const directionUpdate = reactive({ reverseHeight: false, reverseWidth: false })

    onMounted(() => {
      moveListeners.forEach(({ listener, nodeRef }) => {
        if (nodeRef.value) {
          nodeRef.value.addEventListener('mousedown', listener)
        }
      })

      document.addEventListener('mouseup', stopResizing)

      document.addEventListener('mousemove', updateSize)
    })

    const moveListeners: Array<{
      listener: () => void
      nodeRef: Ref<HTMLSpanElement | undefined>
    }> = []

    const createListener = (options = { reverseHeight: false, reverseWidth: false }) => {
      const { reverseWidth, reverseHeight } = options

      const nodeRef = ref<HTMLSpanElement>()
      const listener = () => {
        if (imgRef.value) {
          directionUpdate.reverseHeight = reverseHeight
          directionUpdate.reverseWidth = reverseWidth

          isResizing.value = true
        }
      }

      moveListeners.push({ listener, nodeRef })

      return nodeRef
    }

    const imgRef = ref<HTMLImageElement>()
    const leftTopRef = createListener({ reverseWidth: true, reverseHeight: true })
    const leftBottomRef = createListener({ reverseWidth: true, reverseHeight: false })
    const rightTopRef = createListener({ reverseWidth: false, reverseHeight: true })
    const rightBottomRef = createListener({ reverseWidth: false, reverseHeight: false })

    const isDraggable = computed(() => props.node?.attrs?.isDraggable)

    const nodeAttrs = computed(() => props.node.attrs)

    const updateSize = (event: MouseEvent) => {
      if (!isResizing.value) {
        return
      }

      if (imgRef.value) {
        const height = imgRef.value.height + (directionUpdate.reverseHeight ? -event.movementY : event.movementY)
        const width = imgRef.value.width + (directionUpdate.reverseWidth ? -event.movementX : event.movementX)

        if (height < 100 || width < 100) {
          return
        }

        props.updateAttributes({ width, height })
      }
    }

    const stopResizing = () => {
      isResizing.value = false
    }

    const toggleActive = (value: boolean) => {
      isActive.value = value

      if (!value) {
        stopResizing()
      }
    }

    onUnmounted(() => {
      moveListeners.forEach(({ listener, nodeRef }) => {
        if (nodeRef.value) {
          nodeRef.value.removeEventListener('mousedown', listener)
        }
      })

      document.removeEventListener('mouseup', stopResizing)

      document.removeEventListener('mousemove', updateSize)
    })

    return {
      nodeAttrs,

      isDraggable,
      isActive,

      leftTopRef,
      leftBottomRef,
      rightTopRef,
      rightBottomRef,
      imgRef,

      toggleActive,
    }
  },
})
