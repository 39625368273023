
import { defineComponent } from 'vue'
import { SvoiIcon } from '@svoi-ui/components/icon'

export default defineComponent({
  components: { SvoiIcon },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
})
