
import { defineComponent } from 'vue'
import { computed } from '@nuxtjs/composition-api'
import { SvoiIcon } from '@svoi-ui/components/icon'
import { SvoiTooltipped } from '@svoi-ui/components/tooltip'
import { getEditorToolsByKeys, useEditor } from '~/composables/editor'
import { EditorActivatorDropdown } from '~/widgets/editor/editor-tools/dropdown'
import { BaseDropdown } from '~/shared/ui/base'

export default defineComponent({
  components: { SvoiTooltipped, SvoiIcon, EditorActivatorDropdown, BaseDropdown },
  setup() {
    const defaultColor = '#101e2c'

    const colorScheme = [
      [defaultColor, '#845EF7', '#339AF0', '#22B8CF', '#51CF66', '#FCC419', '#FF6B6B', '#F06595'],
      ['#CED4DA', '#5F3DC4', '#1864AB', '#0B7285', '#2B8A3E', '#E67700', '#C92A2A', '#C2255C'],
    ]

    const editor = useEditor()
    const t = getEditorToolsByKeys(editor, ['textColor'])

    const tool = computed(() => {
      if (!t.value) {
        return null
      }

      return t.value.textColor
    })

    const activeColor = computed(() => colorScheme.flat().find((color) => tool.value?.active(color)) || defaultColor)

    const onClickColor = (color: string) => {
      if (tool.value) {
        tool.value.action(color)
      }
    }

    return {
      tool,
      defaultColor,
      activeColor,
      colorScheme,
      onClickColor,
    }
  },
})
