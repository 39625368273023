
import { defineComponent } from 'vue'
import { computed, ref } from '@nuxtjs/composition-api'
import { SvoiIcon } from '@svoi-ui/components/icon'
import { getEditorToolsByKeys, useEditor } from '~/composables/editor'
import { uuid } from '~/shared/utils/helpers'
import { BaseDropdown } from '~/shared/ui/base'

interface PositionCell {
  cols: number
  rows: number
}

export default defineComponent({
  components: { SvoiIcon, BaseDropdown },
  setup() {
    const editor = useEditor()
    const t = getEditorToolsByKeys(editor, ['insertTable'])

    const tool = computed(() => {
      if (!t.value) {
        return null
      }

      return t.value.insertTable
    })

    const minRows = 4
    const minCols = 8
    const maxRows = 10
    const maxCols = 10

    const renderSize = ref<PositionCell>({ cols: minCols, rows: minRows })
    const hoverSize = ref<PositionCell>({ cols: 1, rows: 1 })

    const createArray = (length: number) => Array.from({ length }, (_, idx) => ({ id: uuid(), index: idx + 1 }))

    const grid = computed(() =>
      createArray(renderSize.value.rows).map((row, index) => ({
        id: row.id,
        index: index + 1,
        col: createArray(renderSize.value.cols),
      }))
    )

    const onMouseoverCell = ({ rows, cols }: PositionCell) => {
      hoverSize.value = { cols: cols || 1, rows: rows || 1 }

      const renderedRows = renderSize.value.rows
      const renderedCols = renderSize.value.cols

      const diffRows = renderedRows - rows
      const diffCols = renderedCols - cols

      // Cols
      if (diffCols > 1 && renderedCols > minCols) {
        renderSize.value.cols--
      }

      if (diffCols === 0 && renderedCols < maxCols) {
        renderSize.value.cols++
      }

      // Rows
      if (diffRows > 1 && renderedRows > minRows) {
        renderSize.value.rows--
      }

      if (diffRows === 0 && renderedRows < maxRows) {
        renderSize.value.rows++
      }
    }

    const onInsertTable = () => {
      tool.value?.action(hoverSize.value)
    }

    const getCellClasses = ({ rows, cols }: PositionCell) => ({
      'grid-cell-wrapper': true,
      'grid-cell-wrapper-hover': cols <= hoverSize.value.cols && rows <= hoverSize.value.rows,
    })

    const activatorClasses = (isDropdownOpen: boolean) => ({
      'activator-active': isDropdownOpen,
      'activator-disabled': tool.value?.disabled,
      activator: true,
    })

    return {
      grid,
      renderSize,
      hoverSize,
      tool,
      getCellClasses,
      activatorClasses,
      onMouseoverCell,
      onInsertTable,
    }
  },
})
