import { mergeAttributes, Node } from '@tiptap/core'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    tableCell: {
      setCellVerticalAlign: (color: string) => ReturnType

      unsetCellVerticalAlign: () => ReturnType
    }
  }
}

export interface TableCellOptions {
  HTMLAttributes: Record<string, any>
  defaultAlignment: string
  alignments: string[]
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const TableCell = Node.create<TableCellOptions>({
  name: 'tableCell',

  addOptions() {
    return {
      HTMLAttributes: {},
      alignments: ['top', 'middle', 'bottom'],
      defaultAlignment: 'top',
    }
  },

  content: 'block+',

  addAttributes() {
    return {
      colspan: {
        default: 1,
      },
      rowspan: {
        default: 1,
      },
      colwidth: {
        default: null,
        parseHTML: (element) => {
          const colwidth = element.getAttribute('colwidth')
          const value = colwidth ? [parseInt(colwidth, 10)] : null

          return value
        },
      },
      verticalAlign: {
        default: this.options.defaultAlignment,
        parseHTML: (element) => element.style.verticalAlign || this.options.defaultAlignment,
        renderHTML: (attributes) => {
          if (attributes.valign === this.options.defaultAlignment) {
            return {}
          }

          return { style: `vertical-align: ${attributes.verticalAlign}` }
        },
      },
    }
  },

  tableRole: 'cell',

  isolating: true,

  parseHTML() {
    return [{ tag: 'td' }]
  },

  renderHTML(props) {
    return ['td', mergeAttributes(this.options.HTMLAttributes, props.HTMLAttributes), 0]
  },

  addCommands() {
    return {
      setCellVerticalAlign:
        (alignment: string) =>
        ({ commands }) => {
          if (!this.options.alignments.includes(alignment)) {
            return false
          }

          return commands.updateAttributes('tableCell', { verticalAlign: alignment })
        },

      unsetCellVerticalAlign:
        () =>
        ({ commands }) => {
          return commands.resetAttributes('tableCell', 'top')
        },
    }
  },
})
