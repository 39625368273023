
import { defineComponent } from 'vue'
import { SvoiTooltipped } from '@svoi-ui/components/tooltip'
import {
  EditorDefaultToolView,
  EditorDividerToolView,
  EditorToolViews,
  EditorTypeToolsView,
  OmitUnionEditorToolViews,
} from '~/interfaces/editor/editor-view'
import { uuid } from '~/shared/utils/helpers'
import {
  EditorDefaultTool,
  EditorDividerTool,
  EditorHeadingTool,
  EditorImageTool,
  EditorInsertTableTool,
  EditorLinkTool,
  EditorSymbolsTool,
  EditorTextAlignTool,
  EditorTextColorTool,
  EditorVideoTool,
} from '~/widgets/editor/editor-tools/tools'

export default defineComponent({
  components: { SvoiTooltipped },
  setup() {
    const toolsScheme: Array<OmitUnionEditorToolViews<'component' | 'key'>> = [
      {
        type: 'default',

        tooltip: 'Отменить',
        props: {
          toolKey: 'undo',
          icon: 'arrow/undo',
        },
      },
      {
        type: 'default',

        tooltip: 'Повторить',
        props: {
          toolKey: 'redo',
          icon: 'arrow/redo',
        },
      },
      { type: 'divider' },
      { type: 'heading' },
      { type: 'divider' },
      { type: 'text-align' },
      { type: 'divider' },
      { type: 'text-color' },
      {
        type: 'default',

        tooltip: 'Полужирный',
        props: {
          toolKey: 'bold',
          icon: 'paragraph/text-bold',
        },
      },
      {
        type: 'default',

        tooltip: 'Курсив',
        props: {
          toolKey: 'italic',
          icon: 'paragraph/text-italic',
        },
      },
      {
        type: 'default',

        tooltip: 'Подчеркнутый',
        props: {
          toolKey: 'underline',
          icon: 'paragraph/text-underline',
        },
      },
      {
        type: 'default',

        tooltip: 'Зачеркнутый',
        props: {
          toolKey: 'strikethrough',
          icon: 'paragraph/strikethrough',
        },
      },
      {
        type: 'default',

        tooltip: 'Верхний индекс',
        props: {
          toolKey: 'superscript',
          icon: 'paragraph/superscript',
        },
      },
      {
        type: 'default',

        tooltip: 'Нижний индекс',
        props: {
          toolKey: 'subscript',
          icon: 'paragraph/interlinear',
        },
      },
      { type: 'divider' },
      {
        type: 'default',

        tooltip: 'Ненумерованный список',
        props: {
          toolKey: 'bulletList',
          icon: 'content-edit/list',
        },
      },
      {
        type: 'default',

        tooltip: 'Нумерованный список',
        props: {
          toolKey: 'orderedList',
          icon: 'content-edit/list_numbered',
        },
      },
      {
        type: 'default',

        tooltip: 'Уменьшить отступ для списка',
        props: {
          toolKey: 'liftListItem',
          icon: 'arrow/arrow-circle-left',
        },
      },
      {
        type: 'default',

        tooltip: 'Увеличить отступ для списка',
        props: {
          toolKey: 'sinkListItem',
          icon: 'arrow/arrow-circle-right',
        },
      },
      { type: 'divider' },
      { type: 'link', tooltip: 'Добавить ссылку (для выделенного текста)' },
      { type: 'insertTable', tooltip: 'Добавить таблицу' },
      { type: 'image', tooltip: 'Добавить изображение' },
      {
        type: 'default',

        tooltip: 'Цитата',
        props: {
          toolKey: 'blockquote',
          icon: 'paragraph/quote-up',
        },
      },
      {
        type: 'video',
        tooltip: 'Видео',
      },
      { type: 'symbols' },
    ]

    const resolveComponentByType = (type: EditorTypeToolsView) => {
      switch (type) {
        case 'divider':
          return EditorDividerTool
        case 'video':
          return EditorVideoTool
        case 'image':
          return EditorImageTool
        case 'link':
          return EditorLinkTool
        case 'insertTable':
          return EditorInsertTableTool
        case 'text-align':
          return EditorTextAlignTool
        case 'heading':
          return EditorHeadingTool
        case 'text-color':
          return EditorTextColorTool
        case 'symbols':
          return EditorSymbolsTool
        case 'default':
          return EditorDefaultTool
        default:
          return EditorDefaultTool
      }
    }

    const tools: Array<EditorToolViews> = toolsScheme.map((tool) => {
      return {
        ...tool,
        key: uuid(),
        component: resolveComponentByType(tool.type),
      }
    })

    const isDefaultComponent = (tool: EditorToolViews): tool is EditorDefaultToolView => {
      return tool.type === 'default'
    }

    const isDividerComponent = (tool: EditorToolViews): tool is EditorDividerToolView => {
      return tool.type === 'divider'
    }

    return { tools, isDefaultComponent, isDividerComponent }
  },
})
