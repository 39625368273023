import { mergeAttributes, Node, nodePasteRule } from '@tiptap/core'
import { getEmbedUrlFromVkUrl, isValidVkUrl, VK_REGEXP_GLOBAL } from './utils'

type SetVkVideoOptions = { src: string; width?: number; height?: number }

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    vk: {
      setVkVideo: (options: SetVkVideoOptions) => ReturnType
    }
  }
}

export interface VkOptions {
  height: number
  width: number
  inline: boolean
  allowFullscreen: boolean
  frameborder: number
  allow: Array<'encrypted-media' | 'fullscreen' | 'picture-in-picture'>
}

export const vk = Node.create<VkOptions>({
  name: 'vk',

  addOptions() {
    return {
      height: 480,
      width: 640,
      inline: false,
      allowFullscreen: true,
      frameborder: 0,
      allow: ['encrypted-media', 'fullscreen', 'picture-in-picture'],
    }
  },

  inline() {
    return this.options.inline
  },

  group() {
    return this.options.inline ? 'inline' : 'block'
  },

  draggable: true,

  addAttributes() {
    return {
      src: {
        default: null,
      },
      width: {
        default: this.options.width,
      },
      height: {
        default: this.options.height,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-vk-video] iframe',
      },
    ]
  },

  addCommands() {
    return {
      setVkVideo:
        (options: SetVkVideoOptions) =>
        ({ commands }) => {
          if (!isValidVkUrl(options.src)) {
            return false
          }

          return commands.insertContent({
            type: this.name,
            attrs: options,
          })
        },
    }
  },

  addPasteRules() {
    return [
      nodePasteRule({
        find: VK_REGEXP_GLOBAL,
        type: this.type,
        getAttributes: (match) => {
          return { src: match.input }
        },
      }),
    ]
  },

  renderHTML(params) {
    const { HTMLAttributes: attrs } = params

    const embedUrl = getEmbedUrlFromVkUrl({
      url: attrs.src,
    })

    attrs.src = embedUrl

    return [
      'div',
      { 'data-vk-video': '' },
      [
        'iframe',
        mergeAttributes(
          {
            width: this.options.width,
            height: this.options.height,
            allowFullscreen: this.options.allowFullscreen,
            frameborder: this.options.frameborder,
            allow: this.options.allow.join('; '),
          },
          attrs
        ),
      ],
    ]
  },
})

export { vk as Vk }
