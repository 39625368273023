import { Editor } from '@tiptap/vue-2'
import { computed, ComputedRef, Ref } from '@nuxtjs/composition-api'
import { EditorKeyTools, EditorTools } from '~/interfaces/editor'
import { useEditorTrigger } from '~/composables/editor/use-editor/use-editor'

const triggerRefEditor = <F extends (...args: any[]) => any>(func: F) => {
  const triggerRef = useEditorTrigger()

  return function (...args: Parameters<F>): ReturnType<F> {
    const result = func.apply(null, args)

    // Так как для оптимизации используется shallowRef то в некоторых случаях при срабатывыании editor actions (e.x toggleBold, toggleUnderline)
    // не происходит необходимых рендеров, чтобы решить данную проблему необходимо вызывать триггер изменения ref после срабатывания editor actions
    triggerRef()

    return result
  }
}

export const getEditorTools = (e: Editor): EditorTools => ({
  // History
  undo: {
    key: 'undo',
    disabled: !e.can().chain().undo().run(),
    action: () => e.chain().focus().undo().run(),
  },
  redo: {
    key: 'redo',
    disabled: !e.can().chain().redo().run(),
    action: () => e.chain().focus().redo().run(),
  },
  //

  // Heading
  heading: {
    key: 'heading',
    disabled: (level) => !e.can().chain().toggleHeading({ level }).run(),
    active: (level) => e.isActive({ level }),
    action: (level) => e.chain().focus().toggleHeading({ level }).run(),
  },

  // Text decoration
  bold: {
    key: 'bold',
    action: triggerRefEditor(() => e.chain().focus().toggleBold().run()),
    active: e.isActive('bold'),
    disabled: !e.can().chain().toggleBold().run(),
  },
  italic: {
    key: 'italic',
    action: triggerRefEditor(() => e.chain().focus().toggleItalic().run()),
    active: e.isActive('italic'),
    disabled: !e.can().chain().toggleItalic().run(),
  },
  underline: {
    key: 'underline',
    action: triggerRefEditor(() => e.chain().focus().toggleUnderline().run()),
    active: e.isActive('underline'),
    disabled: !e.can().chain().toggleUnderline().run(),
  },
  strikethrough: {
    key: 'strikethrough',
    action: triggerRefEditor(() => e.chain().focus().toggleStrike().run()),
    active: e.isActive('strike'),
    disabled: !e.can().chain().toggleStrike().run(),
  },
  textColor: {
    key: 'textColor',
    action: (color: string) => e.chain().focus().setColor(color).run(),
    active: (color: string) => e.isActive('textStyle', { color }),
    currentColor: () => e.getAttributes('textStyle').color,
  },
  //

  // List
  orderedList: {
    key: 'orderedList',
    action: () => e.chain().focus().toggleOrderedList().run(),
  },
  bulletList: {
    key: 'bulletList',
    action: () => e.chain().focus().toggleBulletList().run(),
  },
  sinkListItem: {
    key: 'sinkListItem',
    active: e.isActive('sinkListItem'),
    disabled: !e.can().chain().sinkListItem('listItem').run(),
    action: () => e.chain().focus().sinkListItem('listItem').run(),
  },
  liftListItem: {
    key: 'liftListItem',
    active: e.isActive('liftListItem'),
    disabled: !e.can().chain().liftListItem('listItem').run(),
    action: () => e.chain().focus().liftListItem('listItem').run(),
  },
  //

  // Aligning
  left: {
    key: 'left',
    active: e.isActive({ textAlign: 'left' }),
    disabled: !e.can().chain().setTextAlign('left').run(),
    action: () => e.chain().focus().setTextAlign('left').run(),
  },
  right: {
    key: 'right',
    active: e.isActive({ textAlign: 'right' }),
    disabled: !e.can().chain().setTextAlign('right').run(),
    action: () => e.chain().focus().setTextAlign('right').run(),
  },
  center: {
    key: 'center',
    active: e.isActive({ textAlign: 'center' }),
    disabled: !e.can().chain().setTextAlign('center').run(),
    action: () => e.chain().focus().setTextAlign('center').run(),
  },
  justify: {
    key: 'justify',
    active: e.isActive({ textAlign: 'justify' }),
    disabled: !e.can().chain().setTextAlign('justify').run(),
    action: () => e.chain().focus().setTextAlign('justify').run(),
  },

  top: {
    key: 'top',
    action: () => e.chain().focus().setCellVerticalAlign('top').run(),
  },
  bottom: {
    key: 'bottom',
    action: () => e.chain().focus().setCellVerticalAlign('bottom').run(),
    active: e.isActive('bottom'),
  },
  verticalCenter: {
    key: 'verticalCenter',
    action: () => e.chain().focus().setCellVerticalAlign('middle').run(),
    active: e.isActive('middle'),
  },
  //

  // Link
  addLink: {
    key: 'addLink',
    active: e.isActive('link'),
    disabled: !e.isActive('link'),
    currentLink: () => e.getAttributes('link').href,
    action: (url: string) =>
      e.chain().focus().clearNodes().unsetAllMarks().extendMarkRange('link').setLink({ href: url }).run(),
  },
  openLink: {
    key: 'openLink',
    action: () => {
      const link = e.getAttributes('link').href

      if (!link) {
        return
      }

      window.open(link, '_blank')
    },
  },
  removeLink: {
    key: 'removeLink',
    action: () => e.chain().focus().unsetLink().run(),
    disabled: !e.isActive('link'),
  },
  //

  // Table
  insertTable: {
    key: 'insertTable',
    action: ({ cols, rows }) => e.chain().focus().insertTable({ rows, cols }).run(),
  },
  toggleHeaderColumn: {
    key: 'toggleHeaderColumn',
    action: () => e.chain().focus().toggleHeaderColumn().run(),
  },
  toggleHeaderRow: {
    key: 'toggleHeaderRow',
    action: () => e.chain().focus().toggleHeaderRow().run(),
  },
  colBefore: {
    key: 'colBefore',
    disabled: !e.can().chain().addColumnBefore().run(),
    action: () => e.chain().focus().addColumnBefore().run(),
  },
  colAfter: {
    key: 'colAfter',
    disabled: !e.can().chain().addColumnAfter().run(),
    action: () => e.chain().focus().addColumnAfter().run(),
  },
  rowBefore: {
    key: 'rowBefore',
    disabled: !e.can().chain().addRowBefore().run(),
    action: () => e.chain().focus().addRowBefore().run(),
  },
  rowAfter: {
    key: 'rowAfter',
    disabled: !e.can().chain().addRowAfter().run(),
    action: () => e.chain().focus().addRowAfter().run(),
  },
  colDelete: {
    key: 'colDelete',
    disabled: !e.can().chain().deleteColumn().run(),
    action: () => e.chain().focus().deleteColumn().run(),
  },
  rowDelete: {
    key: 'rowDelete',
    disabled: !e.can().chain().deleteRow().run(),
    action: () => e.chain().focus().deleteRow().run(),
  },
  mergeCells: {
    key: 'mergeCells',
    disabled: !e.can().chain().mergeCells().run(),
    action: () => e.chain().focus().mergeCells().run(),
  },
  splitCell: {
    key: 'splitCell',
    disabled: !e.can().chain().splitCell().run(),
    action: () => e.chain().focus().splitCell().run(),
  },
  deleteTable: {
    key: 'deleteTable',
    disabled: !e.can().chain().deleteTable().run(),
    action: () => e.chain().focus().deleteTable().run(),
  },
  //

  // Image
  insertImage: {
    key: 'insertImage',
    action: (url) => e.chain().focus().setImage({ src: url }).run(),
  },

  // Video
  insertYoutubeVideo: {
    key: 'insertYoutubeVideo',
    action: (url) => e.chain().focus().setYoutubeVideo({ src: url }).run(),
  },
  insertRutubeVideo: {
    key: 'insertRutubeVideo',
    action: (url) => e.chain().focus().setRutubeVideo({ src: url }).run(),
  },
  insertVkVideo: {
    key: 'insertVkVideo',
    action: (url) => e.chain().focus().setVkVideo({ src: url }).run(),
  },

  // Others
  blockquote: {
    key: 'blockquote',
    action: triggerRefEditor(() => e.chain().focus().toggleBlockquote().run()),
    active: e.isActive('blockquote'),
  },
  subscript: {
    key: 'subscript',
    action: triggerRefEditor(() => e.chain().focus().unsetSuperscript().toggleSubscript().run()),
    active: e.isActive('subscript'),
  },
  superscript: {
    key: 'superscript',
    action: triggerRefEditor(() => e.chain().focus().unsetSubscript().toggleSuperscript().run()),
    active: e.isActive('superscript'),
  },
  paragraph: {
    key: 'paragraph',
    action: () => e.chain().focus().setParagraph().run(),
    active: e.isActive('paragraph'),
  },
  //

  reset: {
    key: 'reset',
    action: () => e.chain().focus().clearNodes().unsetAllMarks().run(),
  },
})

export const getEditorToolsByKeys = <K extends EditorKeyTools>(
  editor: Ref<Editor | undefined>,
  keys: Array<K>
): ComputedRef<Pick<EditorTools, K> | null> => {
  return computed(() => {
    if (!editor.value) {
      return null
    }

    const tools = getEditorTools(editor.value)

    return keys.reduce<Pick<EditorTools, K>>((accum, key) => {
      accum[key] = tools[key]

      return accum
    }, {} as Pick<EditorTools, K>)
  })
}
