export const VK_REGEX = /^(https:\/\/)(vk\.com)/
export const VK_REGEXP_GLOBAL = /^(https:\/\/)(vk\.com)(\/video[-\w]+\/?)/g

export const isValidVkUrl = (url: string) => {
  return url.match(VK_REGEX)
}

export const isValidVkGlobalUrl = (url: string) => {
  return url.match(VK_REGEXP_GLOBAL)
}

interface GetEmbedUrlOptions {
  url: string
}

export const getEmbedUrlFromVkUrl = (options: GetEmbedUrlOptions) => {
  const { url } = options

  if (!isValidVkUrl(url)) {
    return null
  }

  if (url.includes('/video_ext.php/')) {
    return url
  }

  const videoIdRegex = /video-([-\w]+)_([-\w]+)/gm
  const matches = videoIdRegex.exec(url)

  if (Array.isArray(matches) && matches?.length < 3) {
    return null
  }

  const outputUrl: Array<String> = ['https://vk.com/video_ext.php']

  const params: Array<string> = []

  const oid = matches?.[1]
  const id = matches?.[2]

  if (!oid || !id) {
    return null
  }

  params.push(`oid=-${oid}&id=${id}&hd=2`)

  return `${outputUrl}?${params.join('&')}`
}
