
import { defineComponent } from '@nuxtjs/composition-api'
import { BubbleMenu } from '@tiptap/vue-2'
import type { BubbleMenuPluginProps } from '@tiptap/extension-bubble-menu'
import { SvoiTooltipped } from '@svoi-ui/components/tooltip'
import { SvoiIcon } from '@svoi-ui/components/icon'
import { useEditor } from '~/composables/editor'
import { EditorDefaultTool, EditorLinkTool } from '~/widgets/editor/editor-tools/tools'
import { EditorActivatorDropdown } from '~/widgets/editor/editor-tools/dropdown'

export default defineComponent({
  components: {
    SvoiIcon,
    EditorActivatorDropdown,
    EditorLinkTool,
    SvoiTooltipped,
    EditorDefaultTool,
    BubbleMenu,
  },

  setup() {
    const editor = useEditor()

    const shouldShow = (props: BubbleMenuPluginProps) => {
      const { editor } = props

      return editor.isActive('link')
    }

    return { editor, shouldShow }
  },
})
