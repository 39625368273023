import {
  inject,
  InjectionKey,
  onBeforeUnmount,
  onMounted,
  provide,
  ShallowRef,
  shallowRef,
  triggerRef,
  useContext,
} from '@nuxtjs/composition-api'
import { Editor } from '@tiptap/vue-2'
import { getEditorProps } from './options/editor-props/editor-props'
import type { EditorExtensionsProps, EditorTrigger, JSONContent } from '~/interfaces/editor'
import { uuid } from '~/shared/utils/helpers'
import { debounce } from '~/shared/utils/decorators'
import { getEditorExtensions } from '~/composables/editor/use-editor/options/editor-extensions/editor-extensions'

const providedEditorKey = Symbol(`EDITOR_KEY-${uuid()}`) as InjectionKey<ShallowRef<Editor>>
const provideEditorTrigger = Symbol(`EDITOR_TRIGGER_KEY-${uuid()}`) as InjectionKey<EditorTrigger>

export const initEditor = (
  initContent: JSONContent | JSONContent[],
  options: { extensions: EditorExtensionsProps; editable: boolean }
) => {
  // Использование shallow ref и триггеров обусловлено тем, что если использовать ref,
  // то экземпляр класса Editor вызывает большое количество рендеров на любое изменения, из-за чего компоненты будут тормозить,
  // поэтому в данном случае использованы events предоставляемые https://tiptap.dev/docs/editor/api/events,
  // для того чтобы минимизировать количество рендеров при изменениях
  const editor = shallowRef<Editor>()
  const context = useContext()

  const { editable, extensions } = options

  const trigger = () => {
    triggerRef(editor)
  }

  const debounceTrigger = debounce(trigger, 50)

  onMounted(() => {
    editor.value = new Editor({
      editable,
      content: initContent || null,

      extensions: getEditorExtensions(extensions),
      editorProps: getEditorProps(context),

      onUpdate: () => {
        debounceTrigger()
      },
      onSelectionUpdate: () => {
        debounceTrigger()
      },
    })

    provide(provideEditorTrigger, trigger)
    provide(providedEditorKey, editor)
  })

  onBeforeUnmount(() => {
    if (editor.value) {
      editor.value.destroy()
    }
  })

  return editor
}

export const useEditor = (): ShallowRef<Editor> => {
  return inject(providedEditorKey) as ShallowRef<Editor>
}

export const useEditorTrigger = (): EditorTrigger => {
  return inject(provideEditorTrigger) as EditorTrigger
}
