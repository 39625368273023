
import { defineComponent, reactive } from '@nuxtjs/composition-api'
import { SvoiIcon } from '@svoi-ui/components/icon'
import { SvoiInput } from '@svoi-ui/components/input'
import { useVuelidate } from '@vuelidate/core'
import { SvoiButton } from '@svoi-ui/components/button'
import { helpers } from '@vuelidate/validators'
import {
  getEditorToolsByKeys,
  isValidRutubeGlobalUrl,
  isValidRutubeUrl,
  isValidVkGlobalUrl,
  isValidVkUrl,
  isValidYoutubeGlobalUrl,
  isValidYoutubeUrl,
  useEditor,
} from '~/composables/editor'
import { requiredRule } from '~/shared/utils/validation'
import { EditorActivatorDropdown } from '~/widgets/editor/editor-tools/dropdown'
import { BaseDropdown } from '~/shared/ui/base'

export default defineComponent({
  components: { SvoiButton, SvoiInput, BaseDropdown, SvoiIcon, EditorActivatorDropdown },
  setup() {
    const editor = useEditor()
    const tool = getEditorToolsByKeys(editor, ['insertYoutubeVideo', 'insertRutubeVideo', 'insertVkVideo'])

    const editorVideoRule = {
      video: helpers.withMessage('Неверный формат Youtube и Rutube ссылок', (value: string) =>
        Boolean(isValidYoutubeGlobalUrl(value) || isValidRutubeGlobalUrl(value) || isValidVkGlobalUrl(value))
      ),
    }

    const form = useVuelidate({ url: { ...requiredRule, ...editorVideoRule } }, reactive({ url: '' }), {
      $stopPropagation: true,
    })

    const onClickUpdate = () => {
      form.value.$validate().then((isValid) => {
        if (isValid) {
          const url = form.value.url.$model

          if (isValidYoutubeUrl(url)) {
            tool.value?.insertYoutubeVideo.action(url)

            return
          }

          if (isValidRutubeUrl(url)) {
            tool.value?.insertRutubeVideo.action(url)

            return
          }

          if (isValidVkUrl(url)) {
            tool.value?.insertVkVideo.action(url)
          }
        }
      })
    }

    const onOpenDropdown = (isOpen: boolean) => {
      if (isOpen) {
        form.value.url.$model = ''
        form.value.$reset()
      }
    }

    return {
      tool,
      form,
      onClickUpdate,
      onOpenDropdown,
    }
  },
})
