export const RUTUBE_REGEX = /^(https:\/\/)(rutube\.ru)/
export const RUTUBE_REGEX_GLOBAL = /^(https:\/\/)(rutube\.ru)(\/video\/[-\w]+\/?)/g

export const isValidRutubeUrl = (url: string) => {
  return url.match(RUTUBE_REGEX)
}

export const isValidRutubeGlobalUrl = (url: string) => {
  return url.match(RUTUBE_REGEX_GLOBAL)
}

interface GetEmbedUrlOptions {
  url: string
}

export const getEmbedUrlFromRutubeUrl = (options: GetEmbedUrlOptions) => {
  const { url } = options

  if (!isValidRutubeUrl(url)) {
    return null
  }

  if (url.includes('/embed/')) {
    return url
  }

  const videoIdRegex = /video\/([-\w]+)/gm
  const matches = videoIdRegex.exec(url)

  if (!matches?.[1]) {
    return null
  }

  const outputUrl: Array<String> = ['https://www.rutube.ru/play/embed/']

  outputUrl.push(`${matches[1]}`)

  return outputUrl.join('')
}
