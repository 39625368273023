
import { defineComponent } from 'vue'
import { EditorContent } from '@tiptap/vue-2'
import { computed } from '@nuxtjs/composition-api'
import { useEditor } from '~/composables/editor'

export default defineComponent({
  components: { EditorContent },

  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const editor = useEditor()

    const classes = computed(() => ({ 'editor-content': true, 'only-view': props.readonly }))

    return { classes, editor }
  },
})
