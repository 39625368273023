import { mergeAttributes, Node, nodePasteRule } from '@tiptap/core'
import { getEmbedUrlFromRutubeUrl, isValidRutubeUrl, RUTUBE_REGEX_GLOBAL } from './utils'

type SetRutubeVideoOptions = { src: string; width?: number; height?: number }

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    rutube: {
      setRutubeVideo: (options: SetRutubeVideoOptions) => ReturnType
    }
  }
}

export interface RutubeOptions {
  height: number
  width: number
  inline: boolean
}

export const rutube = Node.create<RutubeOptions>({
  name: 'rutube',

  addOptions() {
    return {
      height: 480,
      width: 640,
      inline: false,
    }
  },

  inline() {
    return this.options.inline
  },

  group() {
    return this.options.inline ? 'inline' : 'block'
  },

  draggable: true,

  addAttributes() {
    return {
      src: {
        default: null,
      },
      width: {
        default: this.options.width,
      },
      height: {
        default: this.options.height,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-rutube-video] iframe',
      },
    ]
  },

  addCommands() {
    return {
      setRutubeVideo:
        (options: SetRutubeVideoOptions) =>
        ({ commands }) => {
          if (!isValidRutubeUrl(options.src)) {
            return false
          }

          return commands.insertContent({
            type: this.name,
            attrs: options,
          })
        },
    }
  },

  addPasteRules() {
    return [
      nodePasteRule({
        find: RUTUBE_REGEX_GLOBAL,
        type: this.type,
        getAttributes: (match) => {
          return { src: match.input }
        },
      }),
    ]
  },

  renderHTML(params) {
    const { HTMLAttributes: attrs } = params

    const embedUrl = getEmbedUrlFromRutubeUrl({
      url: attrs.src,
    })

    attrs.src = embedUrl

    return [
      'div',
      { 'data-rutube-video': '' },
      [
        'iframe',
        mergeAttributes(
          {
            width: this.options.width,
            height: this.options.height,
          },
          attrs
        ),
      ],
    ]
  },
})

export { rutube as Rutube }
