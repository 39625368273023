
import { computed, defineComponent, ref, useContext } from '@nuxtjs/composition-api'
import { SvoiIcon } from '@svoi-ui/components/icon'
import { SvoiButton } from '@svoi-ui/components/button'
import { SvoiFilePickerComplex } from '@svoi-ui/components/file-picker'
import { allowedEditorImageTypes, getEditorToolsByKeys, useEditor } from '~/composables/editor'
import { BaseDropdown } from '~/shared/ui/base'
import { EditorActivatorDropdown } from '~/widgets/editor/editor-tools/dropdown'

export default defineComponent({
  components: { SvoiIcon, SvoiFilePickerComplex, SvoiButton, EditorActivatorDropdown, BaseDropdown },
  setup() {
    const ctx = useContext()
    const editor = useEditor()
    const t = getEditorToolsByKeys(editor, ['insertImage'])

    const tool = computed(() => {
      if (!t.value) {
        return null
      }

      return t.value.insertImage
    })

    const acceptTypes = allowedEditorImageTypes.join(',')

    const files = ref<File[]>([])

    const onSaveImage = () => {
      const file = files.value.shift()

      if (file && file instanceof File) {
        ctx.$services.file.uploadFile(file).then((path) => {
          if (path) {
            tool.value?.action(path)
          }
        })
      }
    }

    return { files, tool, acceptTypes, onSaveImage }
  },
})
