import { Image } from '@tiptap/extension-image'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import { ImageResizeTemplate } from '~/widgets/editor/editor-content'

export const imageResize = Image.extend({
  name: 'imageResize',

  addAttributes() {
    return {
      ...this.parent?.(),

      width: {
        default: '100%',
        renderHTML: (attributes) => {
          return {
            width: attributes.width,
          }
        },
      },

      height: {
        default: 'auto',
        renderHTML: (attributes) => {
          return {
            height: attributes.height,
          }
        },
      },

      isDraggable: {
        default: true,
        renderHTML: () => {
          return {}
        },
      },
    }
  },

  addNodeView: () => VueNodeViewRenderer(ImageResizeTemplate),
}).configure({ inline: true })

export { imageResize as ImageResize }
