
import { defineComponent } from 'vue'
import { SvoiIcon } from '@svoi-ui/components/icon'
import { SvoiTooltipped } from '@svoi-ui/components/tooltip'
import { useEditor } from '~/composables/editor'
import { EditorActivatorDropdown } from '~/widgets/editor/editor-tools/dropdown'
import { BaseDropdown } from '~/shared/ui/base'
import { EditorDefaultTool } from '~/widgets/editor/editor-tools/tools'
import { isString } from '~/shared/utils/guards'

export default defineComponent({
  components: {
    EditorDefaultTool,
    SvoiTooltipped,
    SvoiIcon,
    EditorActivatorDropdown,
    BaseDropdown,
  },
  setup() {
    const editor = useEditor()

    const insertTypography = (formatValue: ((value: string) => string) | string) => {
      if (!editor.value) {
        return
      }

      const { view, state } = editor.value
      const { from, to } = view.state.selection
      const text = state.doc.textBetween(from, to, '')

      editor.value
        .chain()
        .focus()
        .insertContentAt({ from, to }, isString(formatValue) ? formatValue : formatValue(text))
        .run()
    }

    const insertEmDash = () => {
      insertTypography('—')
    }

    const insertDoubleAngleQuotation = () => {
      insertTypography((value) => `«${value}»`)
    }

    const insertDoubleQuotation = () => {
      insertTypography((value) => `”${value}“`)
    }

    const insertSingleQuotation = () => {
      insertTypography((value) => `’${value}‘`)
    }

    const tools = [
      { key: 'angleQuotation', action: insertDoubleAngleQuotation, icon: 'paragraph/double-angle-quote' },
      { key: 'doubleQuotation', action: insertDoubleQuotation, icon: 'paragraph/double-quote' },
      { key: 'singleQuotation', action: insertSingleQuotation, icon: 'paragraph/single-quote' },
      { key: 'emDash', action: insertEmDash, icon: 'paragraph/em-dash' },
    ]

    return {
      tools,
      insertEmDash,
    }
  },
})
