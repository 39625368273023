
import { computed, defineComponent } from '@nuxtjs/composition-api'
import { BubbleMenu } from '@tiptap/vue-2'
import type { BubbleMenuPluginProps } from '@tiptap/extension-bubble-menu'
import { SvoiIcon } from '@svoi-ui/components/icon'
import { SvoiTooltipped } from '@svoi-ui/components/tooltip'
import { getEditorToolsByKeys, useEditor } from '~/composables/editor'
import { EditorActivatorDropdown, EditorDropdownItem } from '~/widgets/editor/editor-tools/dropdown'
import { EditorDefaultTool } from '~/widgets/editor/editor-tools/tools'
import { BaseDropdown } from '~/shared/ui/base'

export default defineComponent({
  components: {
    SvoiTooltipped,
    SvoiIcon,
    EditorActivatorDropdown,
    EditorDropdownItem,
    BaseDropdown,
    EditorDefaultTool,
    BubbleMenu,
  },

  setup() {
    const editor = useEditor()

    const tools = getEditorToolsByKeys(editor, [
      'top',
      'bottom',
      'verticalCenter',
      'rowDelete',
      'rowAfter',
      'rowBefore',
      'colDelete',
      'colAfter',
      'colBefore',
      'mergeCells',
      'splitCell',
      'deleteTable',
    ])

    const verticalAlignTable = computed(() => {
      if (!tools.value) {
        return []
      }

      return [
        { ...tools.value.top, title: 'Выровнять по верхнему краю' },
        { ...tools.value.bottom, title: 'Выровнять по нижнему краю' },
        { ...tools.value.verticalCenter, title: 'Выровнять по середине' },
      ]
    })

    const rowTable = computed(() => {
      if (!tools.value) {
        return []
      }

      return [
        { ...tools.value.rowBefore, title: 'Вставить строку выше' },
        { ...tools.value.rowAfter, title: 'Вставить строку ниже' },
      ]
    })

    const columnTable = computed(() => {
      if (!tools.value) {
        return []
      }

      return [
        { ...tools.value.colBefore, title: 'Вставить столбец слева' },
        { ...tools.value.colAfter, title: 'Вставить столбец справа' },
      ]
    })

    const cellTable = computed(() => {
      if (!tools.value) {
        return []
      }

      return [
        { ...tools.value.mergeCells, title: 'Объединить ячейки' },
        { ...tools.value.splitCell, title: 'Разделить ячейки' },
      ]
    })

    const deleteTableActions = computed(() => {
      if (!tools.value) {
        return []
      }

      return [
        { ...tools.value.rowDelete, title: 'Удалить строку' },
        { ...tools.value.colDelete, title: 'Удалить столбец' },
        { ...tools.value.deleteTable, title: 'Удалить таблицу' },
      ]
    })

    const menu = computed(
      () =>
        [
          {
            icon: 'grid/grid-header',
            toolKey: 'toggleHeaderRow',
            key: 1,
            type: 'simple',
            tooltip: 'Заголовок таблицы',
          },
          {
            icon: 'grid/grid-6',
            toolKey: 'toggleHeaderColumn',
            key: 2,
            type: 'simple',
            tooltip: 'Вертикальный заголовок таблицы',
          },
          {
            icon: 'paragraph/textalign-left',
            toolKey: 'left',
            key: 3,
            type: 'simple',
            tooltip: 'Выровнять по левому краю',
          },
          {
            icon: 'paragraph/textalign-right',
            toolKey: 'right',
            key: 4,
            type: 'simple',
            tooltip: 'Выровнять по правому краю',
          },
          {
            icon: 'paragraph/textalign-center',
            toolKey: 'center',
            key: 5,
            type: 'simple',
            tooltip: 'Выровнять по центру',
          },
          {
            icon: 'paragraph/textalign-justifycenter',
            toolKey: 'justify',
            key: 6,
            type: 'simple',
            tooltip: 'Выровнять по ширине',
          },
          {
            activatorIcon: 'essential/trash',
            content: deleteTableActions.value,
            key: 7,
            type: 'complex',
          },
          { activatorIcon: 'grid/grid-row', content: rowTable.value, key: 8, type: 'complex' },
          { activatorIcon: 'grid/grid-column', content: columnTable.value, key: 9, type: 'complex' },
          { activatorIcon: 'grid/grid-cell', content: cellTable.value, key: 10, type: 'complex' },
          { activatorIcon: 'paragraph/vertical-align', content: verticalAlignTable.value, key: 11, type: 'complex' },
        ] as const
    )

    const shouldShow = (props: BubbleMenuPluginProps) => {
      const { editor } = props

      return editor.isActive('table')
    }

    return { menu, editor, shouldShow }
  },
})
