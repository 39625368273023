
import { computed, defineComponent, onMounted, PropType, watch } from '@nuxtjs/composition-api'
import { SvoiHint } from '@svoi-ui/components/hint'
import { initEditor } from '~/composables/editor'
import { EditorContent } from '~/widgets/editor/editor-content'
import { EditorTools } from '~/widgets/editor/editor-tools'
import type { JSONContent } from '~/interfaces/editor'
import { BubbleMenus } from '~/widgets/editor/editor-tools/tools'

export default defineComponent({
  name: 'Editor',
  components: { BubbleMenus, SvoiHint, EditorTools, EditorContent },

  props: {
    value: {
      type: Object as PropType<JSONContent>,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],

  setup(props, { emit }) {
    const editor = initEditor(props.value, {
      editable: !props.readonly,
      extensions: { link: { openOnClick: props.readonly } },
    })

    const classes = computed(() => ({
      editor: !props.readonly,
      '-error': props.error,
    }))

    onMounted(() => {
      if (editor.value) {
        editor.value.on('update', ({ editor }) => {
          emit('input', editor.getJSON())
        })
      }
    })

    watch(
      () => props.value,
      (value) => {
        if (editor.value) {
          const isSame = JSON.stringify(editor.value.getJSON()) === JSON.stringify(value)

          if (isSame) {
            return
          }

          editor.value?.commands.setContent(value, false)
        }
      }
    )

    return { editor, classes }
  },
})
