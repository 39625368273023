// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/fonts/Golos-Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../assets/fonts/Golos-Regular.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../../assets/fonts/Golos-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../../../assets/fonts/Golos-Medium.woff2");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../../../assets/fonts/Golos-Medium.woff");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../../../../assets/fonts/Golos-Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../../../../assets/fonts/Golos-Bold.woff2");
var ___CSS_LOADER_URL_IMPORT_7___ = require("../../../../assets/fonts/Golos-Bold.woff");
var ___CSS_LOADER_URL_IMPORT_8___ = require("../../../../assets/fonts/Golos-Bold.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Golos\";font-display:swap;src:local(\"Golos Regular\"),local(\"Golos-Regular\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");font-weight:400;font-style:normal}@font-face{font-family:\"Golos\";font-display:swap;src:local(\"Golos Medium\"),local(\"Golos-Medium\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"truetype\");font-weight:500;font-style:normal}@font-face{font-family:\"Golos\";font-display:swap;src:local(\"Golos Bold\"),local(\"Golos-Bold\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format(\"truetype\");font-weight:700;font-style:normal}*[data-v-4870ed7c]{box-sizing:border-box}*[data-v-4870ed7c],body[data-v-4870ed7c]{font-family:\"Golos\",sans-serif}body[data-v-4870ed7c]{font-size:16px;line-height:20px;background:#f5f7f9}a[data-v-4870ed7c],body[data-v-4870ed7c]{color:#101e2c}a[data-v-4870ed7c]{text-decoration:none;transition:all .3s ease}.page[data-v-4870ed7c]{flex-grow:1}.activator-dropdown[data-v-4870ed7c]{height:100%;display:flex;align-items:center;grid-gap:4px;gap:4px;border-radius:4px;transition:all .3s ease}.activator-dropdown[data-v-4870ed7c]:hover{background-color:#f8f8f8}.activator-dropdown>.icon-arrow[data-v-4870ed7c]{transform:rotate(0deg);transition:all .3s ease}.activator-dropdown>.icon-arrow-active[data-v-4870ed7c]{transform:rotate(180deg)}.activator-dropdown-active[data-v-4870ed7c],.activator-dropdown-active[data-v-4870ed7c]:hover{background-color:#dedfdf}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
