import { mergeAttributes, Node, textblockTypeInputRule } from '@tiptap/core'
import { getUIUniqId } from '~/shared/utils/helpers'
import { HeadingLevel } from '~/shared/types'
import { headingExtensionName, prefixHeadingExtensionId } from '~/shared/const'

export interface HeadingOptions {
  levels: HeadingLevel[]
  HTMLAttributes: Record<string, any>
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    heading: {
      /**
       * Set a heading node
       */
      setHeading: (attributes: { level: HeadingLevel }) => ReturnType
      /**
       * Toggle a heading node
       */
      toggleHeading: (attributes: { level: HeadingLevel }) => ReturnType
    }
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const Heading = Node.create<HeadingOptions>({
  name: headingExtensionName,

  addOptions() {
    return {
      levels: [1, 2, 3, 4, 5, 6],
      HTMLAttributes: {},
    }
  },

  content: 'inline*',

  group: 'block',

  defining: true,

  addAttributes() {
    return {
      level: {
        default: 1,
        rendered: false,
      },
      uid: {
        default: null,
        renderHTML: (attrs) => {
          if (!attrs.uid) {
            attrs.uid = getUIUniqId()
          }

          return { [prefixHeadingExtensionId]: attrs.uid }
        },
      },
    }
  },

  parseHTML() {
    return this.options.levels.map((level: HeadingLevel) => ({
      tag: `h${level}`,
      attrs: { level },
    }))
  },

  renderHTML(props) {
    const hasLevel = this.options.levels.includes(props.node.attrs.level)
    const level = hasLevel ? props.node.attrs.level : this.options.levels[0]

    return [`h${level}`, mergeAttributes({ ...this.options.HTMLAttributes }, props.HTMLAttributes), 0]
  },

  addCommands() {
    return {
      setHeading:
        (attributes) =>
        ({ commands }) => {
          if (!this.options.levels.includes(attributes.level)) {
            return false
          }

          return commands.setNode(this.name, attributes)
        },
      toggleHeading:
        (attributes) =>
        ({ commands }) => {
          if (!this.options.levels.includes(attributes.level)) {
            return false
          }

          return commands.toggleNode(this.name, 'paragraph', attributes)
        },
    }
  },

  addKeyboardShortcuts() {
    return this.options.levels.reduce(
      (items, level) => ({
        ...items,
        ...{
          [`Mod-Alt-${level}`]: () => this.editor.commands.toggleHeading({ level }),
        },
      }),
      {}
    )
  },

  addInputRules() {
    return this.options.levels.map((level) => {
      return textblockTypeInputRule({
        find: /^(#{1,${level}})\\s$/,
        type: this.type,
        getAttributes: {
          level,
        },
      })
    })
  },
})
